import { Container, Grid, Paper, Collapse, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider, createTheme  } from '@mui/material/styles';

import  CircularProgressWithLabel  from './CircularProgressWithLabel.js'
import databricks_logo from './res/databricks_logo_icon.png';
import databricks_logo_grey from './res/databricks_logo_icon_greyed.png';
import tableau_logo_icon from './res/tableau_logo_icon.png';
import tableau_logo_icon_grey from './res/tableau_logo_icon_grey.png';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';

import './background.css'

/**
 * Convert a UTC time epoch to a human readable date string
 * 
 * @param {int64} epochTime The time in epoch milliseconds. Will be 0 if the job is still running.
 * @returns A string of the format "DD/MM/YYYY HH:MM"
 */
function formatLocalTime(epochTime) {
    // Create a new Date object with the given epoch (in milliseconds)
    const date = new Date(epochTime);
  
    // Get the day, month, year, hours, and minutes from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    // Construct the formatted string
    const formattedString = `${day}/${month}/${year} ${hours}:${minutes}`;
  
    return formattedString;
  }


// Define a red theme
const redPSVTheme = createTheme({
    palette: {
        primary: {
          light: '#f27573',
          main: '#ED1C24',
          dark: '#a73a38',
          contrastText: '#fff',
        },
        secondary: {
          light: '#637bfe',
          main: '#FFFFFF',
          dark: '#2a3eb1',
          contrastText: '#000',
        },
      },

    typography: {
    "fontFamily": `"psv_font"`,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,

    }
});
  

/**
 * Component that contains a title and (last) job status of this MultiButton
 * 
 * @param {string} jobId the jobId for which to render this component
 * @returns A Box component containing the title and last run status of this jobId
 */
const ButtonHeader = ({jobId}) => {

    // Use a state variable for the title and job status
    const [buttonTitle, setTitle] = useState(null);
    const [buttonLastRan, setButtonLastRan] = useState(null);

    // useEffect is used to synchronize the component with the Databricks REST API
    useEffect(() => {
        
        // Request some job info from the Databricks API
        const fetchData = async() => {
            try {
                // Get the title
                const job_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobInfo`, {jobId: jobId});

                setTitle(job_response.data['settings']['name']);
                
                // Check the status of the (last) job run
                const last_run_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList/`, {jobId: jobId});
                
                // Job currently running, indicate it with a subtitle text
                if (last_run_response.data['runs']['0']['state']['life_cycle_state'] === "PENDING" || last_run_response.data['runs']['0']['state']['life_cycle_state'] === "RUNNING") {
                    setButtonLastRan("Momenteel bezig...");
                } else {
                    // Get the UTC end time of when this job last had a completed run
                    let utcEpoch = last_run_response.data['runs']['0']['end_time'];
                
                    // Update the subtitle to reflect when this job last had a completed run in a human readable string
                    setButtonLastRan("Laatste run: " + formatLocalTime(utcEpoch));
                }
            } catch (error) {
                console.log(error);
            }
        };

        // Initial call that populates the component with data
        fetchData(); 

        // Refresh the header every 5 seconds
        const intervalId = setInterval(() => {
            fetchData();
        }, 5000);
        
        // When the component unmounts, clear the intervalID
        return () => clearInterval(intervalId);

    });

    return (

        <Box sx={{ backgroundColor: 'primary.main', padding: 2, textAlign: 'center', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <Typography sx={{wordBreak: "break-word"}} variant="h5" color="text.primary">{buttonTitle}</Typography>
            <Typography variant="subtitle3" color="text.secondary">{buttonLastRan}</Typography>
        </Box>

    );
};


/**
 * A component that shows the dynamic status of the Job(s) that make up this MultiJob
 * 
 * @param {dict} jobData a dict of values that say something about a particular job run
 * @returns A Box component with a collapsible menu of individual jobs and the status of the parent job
 */
const ButtonJobsComponent = ( {jobData} ) => {

    // Use a state variable to track the status of the collapsible menu
    const [isExpanded, setIsExpanded] = useState(false);
    
    // Use a state variable to track whether all encompassing jobs have succeeded
    const [allJobsSuccess, setAllJobsSuccess] = useState(true)
    
    // Use a state variable to track whether any job is running
    const [anyJobBusy, setAnyJobBusy] = useState(false)

    // Expand or collapse the job items within the button's content
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    }

    useEffect(() => {
        // Check if all jobs are successful
        const areAllJobsSuccessful = jobData.every(job => job.isLastRunSuccess);
        setAllJobsSuccess(areAllJobsSuccessful);

        // Check if any job is currently running
        const anyJobBusy = jobData.some(job => job.isRunning)
        setAnyJobBusy(anyJobBusy)
    }, [jobData]);

    return (
        <Box sx={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', borderLeft: 2}}>
            <Grid container direction="column">

                {/* Render a checkmark if all children jobs have succeeded, render a cross otherwise. Render a progress circle with the value of the lowest job progress. */}
                <Grid container item columnSpacing={1} sx={{alignItems: 'center'}}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {anyJobBusy ? (
                            <>
                                <CircularProgressWithLabel
                                variant="determinate"
                                value={Math.min(...jobData.map(job => job.isRunning ? (job.currentRuntime / job.averageRuntime) * 100 : 100))}
                                />
                                <Typography variant="h6" sx={{ marginLeft: 1, display: 'flex', alignItems: 'center'}}> Job(s) bezig...  </Typography>
                            </>
                        ) : (
                            <>
                                {allJobsSuccess ? (
                                    <>
                                        <CheckCircleIcon fontSize="large" sx={{padding: 1, color: 'green'}} />
                                        <Typography variant="h6" sx={{ marginLeft: 1, display: 'flex', alignItems: 'center'}}> Job(s) geslaagd </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CloseIcon fontSize="large" sx={{padding: 1, color: 'red'}} />   
                                        <Typography variant="h6" sx={{ marginLeft: 1, display: 'flex', alignItems: 'center'}}> Job(s) mislukt  </Typography>
                                    </>
                                )}
                            </>
                        )}
                        
                        
                    </Grid>
                </Grid>

            </Grid>
            {/*Render the individual items if the expansion button is true/clicked */}
            {jobData.length > 0 ?
                <>
                <Collapse in={isExpanded}>
                    <Grid container direction="column">
                        {jobData.map((job, index) => (
                            <Grid container item columnSpacing={1} key={index} sx={{ alignItems: 'center' }}>
                                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center'}}>
                                    <>
                                        {job.isRunning ? 
                                            <>
                                                <CircularProgressWithLabel variant="determinate" value={(job.currentRuntime/job.averageRuntime) * 100 >= 99 ? 99 : (job.currentRuntime/job.averageRuntime) * 100}/>
                                                
                                            </>
                                            :
                                            <>
                                                {job.isLastRunSuccess ? 
                                                    <CheckCircleIcon fontSize="small" sx={{padding: 1, color: 'green'}} />
                                                    :
                                                    <CloseIcon fontSize="small" sx={{padding: 1, color: 'red'}} />
                                                }
                                            </>
                                        }

                                    </>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                                    <Typography fontSize="small">{job.runName}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Collapse>
                <Box sx={{ textAlign: 'center'}}> 
                    <IconButton onClick={handleToggle} sx={{
                        color: 'secondary.main',
                        '&:hover': {
                            color: 'primary.dark',
                        },
                        transition: 'color 0.3s',
                    }}>
                    {isExpanded ? <ExpandLess fontSize="medium" /> : <ExpandMore fontSize="medium" />}
                    <Typography variant="body2" color="text.secondary"> {isExpanded ? 'Verberg losse jobs' : 'Toon losse jobs'}</Typography>
                    </IconButton>  
                </Box>
                </>
            : <></>}
        </Box>    
    ); 
}


/**
 * A component that contains the main interactable content of the MultiButton
 * 
 * @param {string} jobId the jobId for which this component should be rendered
 * @returns A Paper component contain the child components that make up the interactable content
 */
const ButtonMultiContent = ( { jobId, containsTableauJob = false } ) => {
    // Use a state variable to indicate what the average running time was of the last x job runs
    const [jobData, setJobData] = useState([]); 

    // Retrieve all the information about a job, such as title, running time etc.
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Get info about this particular job
                const job_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobInfo`, { jobId: jobId });
                
                // Map the (sub)jobs that are part of this parent job
                const multipleJobIds = job_response.data['settings']['tasks'].map(task => task['run_job_task']['job_id']);

                // For each subjob that is in the parent job, retrieve its info
                const jobData = await Promise.all(
                    multipleJobIds.map(async (jobId) => {
                        try {
                            // Query the Databricks API for a list of job runs for this particular job. This list is then used to extract relevant info
                            const response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList`, { jobId: jobId });
                            
                            // Get a list of the job runs
                            const runs = response.data['runs'];

                            // Sum the runtime of all the runs that have been retrieved for this job
                            const runtimeSum = runs.reduce((sum, run) => sum + (run['run_duration'] / 1000), 0);

                            // Divide the sum by the amount of runs that have been retrieved to get an average runtime
                            const averageRuntime = runtimeSum / runs.length;

                            // Also retrieve the title of this job
                            const runName = runs[0]['run_name'];

                            // Get information about the current run or about the last finished run
                            let isRunning;
                            let isLastRunSuccess = false;
                            let currentRuntime = 1; 
                            
                            // Job is currently running
                            if (runs[0]['state']['life_cycle_state'] === "PENDING" || runs[0]['state']['life_cycle_state'] === "RUNNING" || runs[0]['state']['life_cycle_state'] === "QUEUED") {
                                isRunning = true;   
                                currentRuntime = runs[0]['run_duration'] / 1000; 
                            } else {
                                // Job has finished
                                isRunning = false;
                                if (runs[0]['state']['result_state'] === "SUCCESS") {
                                    isLastRunSuccess = true;
                                }
                            }

                            // Return all the information about this job
                            return { runName, currentRuntime, averageRuntime, isRunning, isLastRunSuccess };
                        } catch (error) {
                            console.log(error);
                            return { runName: null, currentRuntime: null, averageRuntime: null, isRunning: null, isLastRunSuccess: null };
                        }
                    })
                );

                // Use the state variable to save the data for this job
                setJobData(jobData);

            } catch (error) {
                console.log(error);
            }
        };

        // Initial call that populates the button
        fetchData();

        // Set the refresh interval on a timer
        const intervalId = setInterval(() => {
            fetchData();
        }, 6000);

        // When component unmounts, clear the interval
        return () => clearInterval(intervalId);

    }, [jobId]);


    // Render the individual children based on the jobId and some information for that jobId in the form of a jobData object
    return ( 
        <Paper elevation={20} sx = {{ display: 'flex', flexDirection: 'row', backgroundColor: 'primary.light', padding: 2}}>
                <ButtonIconComponent jobId={jobId} containsTableauJob={containsTableauJob}/>

                <ButtonJobsComponent jobData={jobData}/>        
        </Paper>
    )
}


/**
 * Define a component that will make up the left side of the Button's main content
 * 
 * @param {string} jobId The jobId for which this component will be rendered 
 * @returns A Box component which contains an icon and some descriptive text about the Job's state
 */
const ButtonIconComponent = ( {jobId, containsTableauJob = false} ) => {
    const [currentRunId, setCurrentRunId] = useState(null); 
    const [jobIsRunning, setJobIsRunning] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); 

    const databricksSrc = isButtonDisabled ? databricks_logo_grey : databricks_logo;
    const tableauSrc = isButtonDisabled ? tableau_logo_icon_grey : tableau_logo_icon;

    // Set up an Enum-like structure to check the possible life cycle states a job run can have
    const life_cycle_state = Object.freeze({
        PENDING: "PENDING",
        RUNNING: "RUNNING",
        TERMINATING: "TERMINATING",
        TERMINATED: "TERMINATED",
        SKIPPED: "SKIPPED",
        INTERNAL_ERROR: "INTERNAL_ERROR",
        BLOCKED: "BLOCKED",
        WAITING_FOR_RETRY: "WAITING_FOR_RETRY",
        QUEUED: "QUEUED"
    });

    // Run a job if the corresponding button has been clicked
    const onClickHandlerRun = async () => {
        try {       
            // Remove the ability of clicking the button again until the job has finished
            setIsButtonDisabled(true);

            const response = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksStartJobRun', {jobId: jobId});
            
            // Retrieve the run_id that was started because of the click
            setCurrentRunId(response.data['run_id']); 
        } catch (error) {
            console.log(error);
        }
    }

    // Used to track the current job's run status, if it's running
    useEffect(() => {
        const fetchRunStatus = async() => {
            try {
                // Only query the API if for job run info if a run is going on
                if (currentRunId) {
                    // Get the info for this run
                    const runInfo = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunInfo', {runId: currentRunId});
                    
                    // Store the status of the current run
                    let currentRunStatus = runInfo.data['state']['life_cycle_state'];
                    
                    // Job run has finished, perform cleanup
                    if (currentRunStatus === life_cycle_state.TERMINATED) {
                        // Reset the button to be clickable again
                        setIsButtonDisabled(false);
                        // Reset the runId
                        setCurrentRunId(null);
                        // Reset the jobIsRunning boolean
                        setJobIsRunning(false); 
                    } else if (
                        currentRunStatus === life_cycle_state.PENDING 
                        || currentRunStatus === life_cycle_state.RUNNING 
                        || currentRunStatus === life_cycle_state.TERMINATING 
                        || currentRunStatus === life_cycle_state.WAITING_FOR_RETRY 
                        || currentRunStatus === life_cycle_state.QUEUED) {

                            // Job is running in some capacity, set the corresponding state variable 
                            setJobIsRunning(true);

                        }
                } else {
                    // Run has ended through some other means, make sure to reactivate the button again
                    const last_run_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList/`, {jobId: jobId});
                    
                    // Extract the state value from the response
                    let last_run_state = last_run_response.data['runs'][0]['state']['life_cycle_state'];
                    
                    // And check whether this state value corresponds to a state that is defined as a "busy" state
                    if (last_run_state === life_cycle_state.RUNNING || last_run_state === life_cycle_state.PENDING) {
                        // Disable functionality of the button 
                        setJobIsRunning(true);
                        setIsButtonDisabled(true);
                    } else {
                        // Enable the button to be clicked again
                        setJobIsRunning(false);
                        setIsButtonDisabled(false);
                    }
                }
            } catch(error) {
                console.log(error);
            }
        }
        
        // Refresh this component on a timer
        const intervalId = setInterval(() => { 
            fetchRunStatus(); 
        }, 2000);
        
        // When component unmounts, clear the interval
        return () => clearInterval(intervalId);
    })

    // Add a hover effect to the button in case it's active
    const hoverEffect = { 
        '&:hover': {
            transform: 'scale(1.07)', // Scale up on hover
            cursor: 'pointer',
        },
        transition: 'transform 0.3s', // Smooth transition
    }

    // Activate the pulse animation in case the job is running
    const pulseAnimation = {
        animation: 'pulse 1s ease-in-out infinite',
        '@keyframes pulse': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(1.05)' },
            '100%': { transform: 'scale(1)' },
        },
    }

    // Return an unclickable pulsating grey icon when a Job's running, return a clickable icon otherwise
    return(
        <Box
            {...(!isButtonDisabled && { onClick: onClickHandlerRun})}
            sx = {{ 
                display: 'flex', 
                flexDirection: 'row', 
                flex: 1, 
                alignItems: 'center', 
                ...(isButtonDisabled ? pulseAnimation : hoverEffect)
             }}>
            <Box 
                sx = {{flex: 1}}>
                {containsTableauJob ? (
                    <>
                        <img
                            src={databricksSrc}
                            alt="databricks_icon"
                            style={{ width: '25%', height: 'auto' }}
                        />
                        <img
                            src={tableauSrc}
                            alt="tableau_icon"
                            style={{ width: '25%', height: 'auto' }}
                        />
                    </>
                ) : (
                    <img
                    src={databricksSrc}
                    alt="databricks_icon"
                    style={{ width: '50%', height: 'auto' }}
                    />
                )}
            </Box>
            <Box sx = {{flex: 1}}>
                <Typography variant="button" color="text.primary">
                    {isButtonDisabled ? "Job Bezig..." : "Run Job"}
                </Typography>    
            </Box>
        </Box>
    )
}

/**
 * Create a small description component that summarizes the goal of the MultiJob
 * 
 * @param {string} jobId The jobId for which the textual description should be retrieved for this Job 
 * @returns A Box component which contains some text 
 */
const ButtonSummaryText = ( {jobId} ) => {

        // Use a state variable that contains the current buttonDescription
        const [buttonDescription, setButtonDescription] = useState(null);

        // useEffect is used to synchronize the component with the Databricks REST API
        useEffect(() => { 
    
            // Get the job description from the Databricks API
            const fetchData = async () => {
                try { 
                    const job_response = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksGetJobInfo', {jobId: jobId});
                    setButtonDescription(job_response.data['settings']['description']);
                } catch (error) { 
                    console.log(error)
                }  
            }
    
            // Initial call that populates the data when component mounts
            fetchData();
        }, [jobId]);
    return ( 
        <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: 'primary.main', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
            <Typography variant="body2" color="text.primary"> {buttonDescription} </Typography>
        </Box>
    )
}


/**
 * Root component for a MultiJob button
 * 
 * @param {string} jobId  
 * @returns A component that represents a Job that consists of multiple other Jobs
 */
function MUIMultipleButton( {jobId, containsTableauJob = false} ) {
    return (
            <ThemeProvider theme={redPSVTheme}>
                <Container>
                    <Box
                        sx={{
                            '&:hover': {
                                transform: 'scale(1.05)', // Scale up on hover
                            },
                            transition: 'transform 0.3s', // Smooth transition
                        }}
                    >
                         
                        <ButtonHeader
                            jobId = {jobId}
                        />
                        <ButtonMultiContent 
                            jobId = {jobId}
                            containsTableauJob = {containsTableauJob}
                        />
                        <ButtonSummaryText
                            jobId = {jobId}
                        />
                    </Box>
                </Container>
            </ThemeProvider>
    )
}

export default MUIMultipleButton;
import { Container, Paper, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider, createTheme  } from '@mui/material/styles';

import  CircularProgressWithLabel  from './CircularProgressWithLabel.js'
import databricks_logo from './res/databricks_logo_icon.png';
import databricks_logo_grey from './res/databricks_logo_icon_greyed.png';
import tableau_logo_icon from './res/tableau_logo_icon.png';
import tableau_logo_icon_grey from './res/tableau_logo_icon_grey.png';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';

import './background.css'

/**
 * Convert a UTC time epoch to a human readable date string
 * 
 * @param {int64} epochTime The time in epoch milliseconds. Will be 0 if the job is still running.
 * @returns A string of the format "DD/MM/YYYY HH:MM"
 */
function formatLocalTime(epochTime) {
    // Create a new Date object with the given epoch (in milliseconds)
    const date = new Date(epochTime);
  
    // Get the day, month, year, hours, and minutes from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    // Construct the formatted string
    const formattedString = `${day}/${month}/${year} ${hours}:${minutes}`;
  
    return formattedString;
  }

// Define a red theme
const redPSVTheme = createTheme({
    palette: {
        primary: {
          light: '#f27573',
          main: '#ED1C24',
          dark: '#a73a38',
          contrastText: '#fff',
        },
        secondary: {
          light: '#637bfe',
          main: '#FFFFFF',
          dark: '#2a3eb1',
          contrastText: '#000',
        },
      },

    typography: {
    "fontFamily": `"psv_font"`,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,

    }
});
  
const ButtonHeader = ({jobId, setLoading}) => {

    // Use a state variable for the title and job status
    const [buttonTitle, setTitle] = useState(null);
    const [buttonLastRan, setButtonLastRan] = useState(null);

    // useEffect is used to synchronize the component with the Databricks REST API
    useEffect(() => {
        
        // Request some job info from the Databricks API
        const fetchData = async() => {
            try {
                // Get the title
                const job_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobInfo`, {jobId: jobId});

                setTitle(job_response.data['settings']['name']);
                
                // Check the status of the (last) job run
                const last_run_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList/`, {jobId: jobId});
                
                // Job currently running, indicate it with a subtitle text
                if (last_run_response.data['runs']['0']['state']['life_cycle_state'] === "PENDING" || last_run_response.data['runs']['0']['state']['life_cycle_state'] === "RUNNING") {
                    setButtonLastRan("Momenteel bezig...");
                } else {
                    // Get the UTC end time of when this job last had a completed run
                    let utcEpoch = last_run_response.data['runs']['0']['end_time'];
                
                    // Update the subtitle to reflect when this job last had a completed run in a human readable string
                    setButtonLastRan("Laatste run: " + formatLocalTime(utcEpoch));
                }
            } catch (error) {
                console.log(error);
            }
        };

        // Initial call that populates the component with data
        fetchData(); 

        setLoading(false);

        // Refresh the header every 5 seconds
        const intervalId = setInterval(() => {
            fetchData();
        }, 5000);
        
        // When the component unmounts, clear the intervalID
        return () => clearInterval(intervalId);

    });

    return (
        <Box sx={{ backgroundColor: 'primary.main', padding: 2, textAlign: 'center', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}> 
        
                    <Typography variant="h5" color="text.primary">{buttonTitle}</Typography>
                    <Typography variant="subtitle3" color="text.secondary">{buttonLastRan}</Typography>
        </Box>
    );
};

const ButtonMainContent = ({jobId, containsTableauJob = false}) => {

    // Use a state variable to indicate whether the job is currently running
    const [isRunning, setIsRunning] = useState(false);

    // Use a state variable to indicate whether the last job run was a success or not
    const [lastRunSuccess, setLastRunSuccess] = useState(false);

    // Use a state variable to indicate what the average running time was of the last x job runs
    const [averageRuntime, setAverageRuntime] = useState(0); 

    // Use a state variable to compute the running time progress compared to the average running time.
    const [progress, setProgress] = useState(0);

    const onClickHandlerRun = async () => {
        try {
            const response = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksStartJobRun', {jobId: jobId});
        } catch (error) {
            console.log(error);
        }
    }
        

    // useEffect is used to synchronize the component with the Databricks REST API
    useEffect(() => {

        // Function that retrieves the job status from the API and sets a state variable based on the result
        const fetchData = async() => {

            try {
                // Get the last job RUN for this particular job
                const running_job_data = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList`, {jobId: jobId} );
                
                // Sum the running times of the last x job runs
                let runtimeSum = 0;
                let runtimeQuantity = running_job_data.data['runs'].length;

                for (let i = 0; i < runtimeQuantity; i++) {
                    // Get the runtime of a specific job run in milliseconds, convert it to seconds and add it to the sum
                    runtimeSum += running_job_data.data['runs'][i]['run_duration'] / 1000;    
                }
                
                // Set the average runtime in seconds
                setAverageRuntime(runtimeSum / runtimeQuantity);

                // And check whether the status indicates that it's currently running
                if (running_job_data.data['runs']['0']['state']['life_cycle_state'] === "PENDING" || running_job_data.data['runs']['0']['state']['life_cycle_state'] === "RUNNING") {
                    // Job is currently running, set the corresponding state variable
                    setIsRunning(true);
                    
                    // Set the progress bar based on the approximate ratio between the current running time and the average running time
                    const current_job_run_id = running_job_data.data['runs']['0']['run_id'];
                    
                    // Get the metadata for the current job run
                    const job_run_metadata = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunInfo', {runId: current_job_run_id});

                    // Compute the time that has passed since starting this job run
                    const currentRuntime = ((Date.now() - job_run_metadata.data['start_time']) / 1000);

                    // Convert it to a percentage of the total run time (average run time) that is known for that job
                    let progressPercentage = (currentRuntime / averageRuntime) * 100;
                    
                    // Always ensure a range between 0% (inclusive) and 100% (exclusive)
                    if (progressPercentage < 0) {
                        progressPercentage = 0;
                    } else if (progressPercentage > 99) {
                        progressPercentage = 99;
                    }
                    setProgress(progressPercentage);
                } else {
                    // Job is not running, check whether the last run was successful or not
                    if (running_job_data.data['runs']['0']['state']['result_state'] === 'SUCCESS') {
                        setLastRunSuccess(true);
                    } else {
                        setLastRunSuccess(false);
                    }
                    setIsRunning(false);
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        // Initial call that populates the component with data
        fetchData();

        // Refresh this component every second
        const intervalId = setInterval(() => {
            fetchData();
        }, 2000);

        // When component unmounts, clear the interval
        return () => clearInterval(intervalId);

    }, [progress])

    if (isRunning) {
        return (
            <Paper elevation={20} sx = {{ display: 'flex', flexDirection: 'row', backgroundColor: 'primary.light', padding: 2}}>
                    <Box sx = {{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        flex: 1, 
                        alignItems: 'center', 
                        animation: 'pulse 1s ease-in-out infinite',
                        '@keyframes pulse': {
                        '0%': { transform: 'scale(1)' },
                        '50%': { transform: 'scale(1.12)' },
                        '100%': { transform: 'scale(1)' },
                        },
                        }}>
                        <Box 
                            sx = {{flex: 1}}>
                            {containsTableauJob ? (
                                <>
                                    <img 
                                        src = {databricks_logo_grey}
                                        alt = "databricks_icon_grey"
                                        style={{ width: '25%', height: 'auto'}}
                                    />
                                    <img
                                        src = {tableau_logo_icon_grey}
                                        alt = "tableau_icon"
                                        style = {{ width: '25%', height: 'auto' }}
                                    />
                                </>
                            ) : (
                                <>
                                    <img 
                                        src = {databricks_logo_grey}
                                        alt = "databricks_icon_grey"
                                        style={{ width: '50%', height: 'auto'}}
                                    />
                                </>
                            )}
                        </Box>
                        <Box sx = {{flex: 1}}>
                            <Typography variant="button" color="text.primary"> Job bezig... </Typography>
                        </Box>
                    </Box>
                        
                <Box sx = {{ borderLeft: 2, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgressWithLabel  variant="determinate" color="secondary" value={progress}/>
                </Box>
            </Paper>
        )
    } else {
        // Job not in progress, either render a green checkmark or a red cross based on whether it was succesful or not.
        if (lastRunSuccess) {
            return (
                <div>
                    <Paper elevation={20} sx = {{ display: 'flex', flexDirection: 'row', backgroundColor: 'primary.light', padding: 2}}>
                        
                        <Box
                            onClick={onClickHandlerRun} 
                            sx = {{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                flex: 1, 
                                alignItems: 'center', 
                                // textAlign: 'center',
                                '&:hover': {
                                    transform: 'scale(1.10)', // Scale up on hover
                                    cursor: 'pointer',
                                },
                                transition: 'transform 0.3s', // Smooth transition
                            }}>
                            <Box 
                                sx = {{flex: 1}}>
                                {containsTableauJob ? (
                                    <>
                                        <img
                                            src={databricks_logo}
                                            alt="databricks_icon"
                                            style={{ width: '30%', height: 'auto', marginRight: '10px' }}
                                        />
                                        <img
                                            src={tableau_logo_icon}
                                            alt="tableau_icon"
                                            style={{ width: '30%', height: 'auto' }}
                                        />
                                    </>                                    
                                ) : (
                                    <img
                                    src={databricks_logo}
                                    alt="databricks_icon"
                                    style={{ width: '50%', height: 'auto' }}
                                    />
                                )}
                            </Box>
                            <Box sx = {{flex: 1}}>
                                <Typography variant="button" color="text.primary"> Run Job </Typography>
                            </Box>
                        </Box>
                        <Box sx = {{ borderLeft: 2, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <CheckCircleIcon fontSize="large" sx = {{padding: 2, color: 'green'}}/>
                            <Typography variant="body2" color="text.primary">Runtime: {Math.floor(parseFloat(averageRuntime/60))} m {Math.floor(parseFloat(averageRuntime%60))} s</Typography>
                        </Box>
                    </Paper>
                </div>
            );
        } else {
            return (
                <Paper elevation={20} sx = {{ display: 'flex', flexDirection: 'row', backgroundColor: 'primary.light', padding: 2,}}>
                    <Box 
                        onClick={onClickHandlerRun}
                        sx = {{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            flex: 1, 
                            alignItems: 'center', 
                            '&:hover': {
                                transform: 'scale(1.10)', // Scale up on hover
                                cursor: 'pointer',
                            },
                            transition: 'transform 0.3s', // Smooth transition
                        }}>
                        <Box 
                            sx = {{
                                flex: 1
                                }}>
                            <img 
                                src = {databricks_logo}
                                alt = "databricks_icon"
                                style={{ width: '50%', height: 'auto'}}
                            />
                        </Box>
                        <Box sx = {{flex: 1}}>
                            <Typography variant="button" color="text.primary"> Run Job </Typography>
                        </Box>
                    </Box>
                    <Box sx = {{borderLeft: 2, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <CloseIcon fontSize="large" sx = {{padding: 2, color: 'red'}}/>
                        <Typography variant="body2" color="text.primary">Runtime: {Math.floor(parseFloat(averageRuntime/60))} m {Math.floor(parseFloat(averageRuntime%60))} s</Typography>
                    </Box>
            </Paper>
            );
        }

    }
}

const ButtonSummaryText = ( {jobId} ) => {

        // Use a state variable that contains the current buttonDescription
        const [buttonDescription, setButtonDescription] = useState(null);

        // useEffect is used to synchronize the component with the Databricks REST API
        useEffect(() => { 
    
            // Get the job description from the Databricks API
            const fetchData = async () => {
                try { 
                    const job_response = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksGetJobInfo', {jobId: jobId});
                    setButtonDescription(job_response.data['settings']['description']);
                } catch (error) { 
                    console.log(error)
                }  
            }
    
            // Initial call that populates the data when component mounts
            fetchData();
    
            // Refresh this every 10 minutes
            const intervalId = setInterval(() => {
                fetchData();
            }, 10 * 60 * 1000);
    
            // When the component unmounts, clear the interval
            return () => clearInterval(intervalId);
    
        }, []);
    return ( 
        <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: 'primary.main', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
            <Typography variant="body2" color="text.primary"> {buttonDescription} </Typography>
        </Box>
    )
}


function MUIButton( {jobId, containsTableauJob = false} ) {

    const [loading, setLoading] = useState(true);

    return (
            <ThemeProvider theme={redPSVTheme}>
                <Container>
                    <Box
                        sx={{
                            '&:hover': {
                                transform: 'scale(1.05)', // Scale up on hover
                            },
                            transition: 'transform 0.3s', // Smooth transition
                        }}
                    > 
                        <ButtonHeader
                            jobId = {jobId}
                            setLoading={setLoading}
                        />
                        <ButtonMainContent
                            jobId = {jobId}
                            containsTableauJob = {containsTableauJob}
                        />
                        
                        <ButtonSummaryText
                            jobId = {jobId}
                        />
                    </Box>
                </Container>
            </ThemeProvider>
    )
}

export default MUIButton;
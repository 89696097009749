import React, { useEffect, useState } from "react";
import databricks_logo from './res/databricks_logo_icon.png';
import databricks_logo_grey from './res/databricks_logo_icon_greyed.png'

import axios from 'axios';
import CircularProgressWithLabel from './CircularProgressWithLabel.js'


import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { green, red } from "@mui/material/colors";


/**
 * Convert a UTC time epoch to a human readable date string
 * 
 * @param {int64} epochTime The time in epoch milliseconds. Will be 0 if the job is still running.
 * @returns A string of the format "DD/MM/YYYY HH:MM"
 */
function formatLocalTime(epochTime) {
    // Create a new Date object with the given epoch (in milliseconds)
    const date = new Date(epochTime);
  
    // Get the day, month, year, hours, and minutes from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    // Construct the formatted string
    const formattedString = `${day}/${month}/${year} ${hours}:${minutes}`;
  
    return formattedString;
  }


/**
 * The header of the button, which displays the title and some status text of this job
 * 
 * @param {string} jobId The ID of the job 
 * @returns A component with a title string and some status text
 */
function ButtonHeader( {jobId}) {
    
    // Use a state variable for the title and job status
    const [buttonTitle, setTitle] = useState(null);
    const [buttonLastRan, setButtonLastRan] = useState(null);

    // useEffect is used to synchronize the component with the Databricks REST API
    useEffect(() => {
        
        // Request some job info from the Databricks API
        const fetchData = async() => {
            try {
                // Get the title
                const job_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobInfo`, {jobId: jobId});

                setTitle(job_response.data['settings']['name']);
                
                // Check the status of the (last) job run
                const last_run_response = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList/`, {jobId: jobId});
                
                // Job currently running, indicate it with a subtitle text
                if (last_run_response.data['runs']['0']['state']['life_cycle_state'] === "PENDING" || last_run_response.data['runs']['0']['state']['life_cycle_state'] === "RUNNING") {
                    setButtonLastRan("Momenteel bezig...");
                } else {
                    // Get the UTC end time of when this job last had a completed run
                    let utcEpoch = last_run_response.data['runs']['0']['end_time'];
                
                    // Update the subtitle to reflect when this job last had a completed run in a human readable string
                    setButtonLastRan("Laatste run: " + formatLocalTime(utcEpoch));
                }
            } catch (error) {
                console.log(error);
            }
        };

        // Initial call that populates the component with data
        fetchData(); 

        // Refresh the header every 5 seconds
        const intervalId = setInterval(() => {
            fetchData();
        }, 5000);
        
        // When the component unmounts, clear the intervalID
        return () => clearInterval(intervalId);

    }, [jobId]);
    
    // Return a component that contains the job title and the job status
    return (
        <div className = "button-header">
            <h3> {buttonTitle} </h3>
            <p><i> {buttonLastRan} </i></p>

        </div>
    );
};

/**
 * Dynamically render content on the right side of the button, based on the job's current status. 
 * 
 * @param {string} jobId The ID of the job 
 * @returns A spinning progress wheel in case the job is in a RUNNING state, 
 *          or a checkmark/cross based on the last job's success state
 */
function ButtonStatusBox({jobId}) {

    // Use a state variable to indicate whether the job is currently running
    const [isRunning, setIsRunning] = useState(false);

    // Use a state variable to indicate whether the last job run was a success or not
    const [lastRunSuccess, setLastRunSuccess] = useState(false);

    // Use a state variable to indicate what the average running time was of the last x job runs
    const [averageRuntime, setAverageRuntime] = useState(0); 

    // Use a state variable to compute the running time progress compared to the average running time.
    const [progress, setProgress] = useState(0);

    // useEffect is used to synchronize the component with the Databricks REST API
    useEffect(() => {

        // Function that retrieves the job status from the API and sets a state variable based on the result
        const fetchData = async() => {

            try {
                // Get the last job RUN for this particular job
                const running_job_data = await axios.post(`https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList`, {jobId: jobId} );
                
                // Sum the running times of the last x job runs
                let runtimeSum = 0;
                let runtimeQuantity = running_job_data.data['runs'].length;

                for (let i = 0; i < runtimeQuantity; i++) {
                    // Get the runtime of a specific job run in milliseconds, convert it to seconds and add it to the sum
                    runtimeSum += running_job_data.data['runs'][i]['run_duration'] / 1000;    
                }
                
                // Set the average runtime in seconds
                setAverageRuntime(runtimeSum / runtimeQuantity);

                // And check whether the status indicates that it's currently running
                if (running_job_data.data['runs']['0']['state']['life_cycle_state'] === "PENDING" || running_job_data.data['runs']['0']['state']['life_cycle_state'] === "RUNNING") {
                    // Job is currently running, set the corresponding state variable
                    setIsRunning(true);
                    
                    // Set the progress bar based on the approximate ratio between the current running time and the average running time
                    const current_job_run_id = running_job_data.data['runs']['0']['run_id'];
                    
                    // Get the metadata for the current job run
                    const job_run_metadata = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunInfo', {runId: current_job_run_id});

                    // Compute the time that has passed since starting this job run
                    const currentRuntime = ((Date.now() - job_run_metadata.data['start_time']) / 1000);

                    // Convert it to a percentage of the total run time (average run time) that is known for that job
                    const progressPercentage = (currentRuntime / averageRuntime) * 100;

                    setProgress(progressPercentage);
                    console.log(progress);
                } else {
                    // Job is not running, check whether the last run was successful or not
                    if (running_job_data.data['runs']['0']['state']['result_state'] === 'SUCCESS') {
                        setLastRunSuccess(true);
                    } else {
                        setLastRunSuccess(false);
                    }
                    setIsRunning(false);
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        // Initial call that populates the component with data
        fetchData();

        // Refresh this component every second
        const intervalId = setInterval(() => {
            fetchData();
        }, 2000);

        // When component unmounts, clear the interval
        return () => clearInterval(intervalId);

    }, [jobId, progress]);
    
    // Render different content in the component based on what the return values of the API call were
    if (isRunning) {
        return (
            // Job currently in progress, indicate it with a circular progress animation
            <div className= "button-status-box">
                
                {/* It's possible that the running time of a job run exceeds the average run time. In that case, freeze the progress component at 99%, just like good old Windows does it. */}
                <CircularProgressWithLabel variant="determinate" value={progress >= 99 ? 99 : progress} />

            </div>
        );
    } else {
        // Job not in progress, either render a green checkmark or a red cross based on whether it was succesful or not.
        if (lastRunSuccess) {
            return (
                <div className= "button-status-box">
                    <CheckCircleIcon 
                        sx = {{ color: green[700], fontSize: 70 }}
                    />
                    <h5>Runtime: {Math.floor(parseFloat(averageRuntime/60))} m {Math.floor(parseFloat(averageRuntime%60))} s </h5>
                </div>
            );
        } else {
            return (
                <div className= "button-status-box">
                    <CloseIcon
                        sx = {{color: red[700], fontSize: 70 }}
                    />
                    <h5>Runtime: {Math.floor(parseFloat(averageRuntime/60))} m {Math.floor(parseFloat(averageRuntime%60))} s</h5>
                </div>
            );
        }

    }

};

/**
 * A small description component that's at the bottom of the button, describing what the job does.
 * 
 * @param {string} jobId the ID of the job
 * @returns A component with a text description of the job
 */

function ButtonSummaryText( {jobId} ) {

    // Use a state variable that contains the current buttonDescription
    const [buttonDescription, setButtonDescription] = useState(null);

    // useEffect is used to synchronize the component with the Databricks REST API
    useEffect(() => { 

        // Get the job description from the Databricks API
        const fetchData = async () => {
            try { 
                const job_response = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksGetJobInfo', {jobId: jobId});
                setButtonDescription(job_response.data['settings']['description']);
            } catch (error) { 
                console.log(error)
            }  
        }

        // Initial call that populates the data when component mounts
        fetchData();

        // Refresh this every 10 minutes
        const intervalId = setInterval(() => {
            fetchData();
        }, 10 * 60 * 1000);

        // When the component unmounts, clear the interval
        return () => clearInterval(intervalId);

    }, [jobId]);

    // Return a component with a job description
    return (
        <div className = "button-summary">
            <p> {buttonDescription} </p>
        </div>
    );
};

/**
 * A small component on the left side of the button that contains a clickable icon
 * 
 * @param {string} jobId the ID of the job
 * @returns A component with a clickable component that activates the job run
 */
function ButtonClickableIcon({jobId}) {

    const [jobIsRunning, setJobIsRunning] = useState(false);

    // Put in a request to start a run for this job
    const onClickHandlerRun = async () => {
        try {
            const response = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksStartJobRun', {jobId: jobId});
        } catch (error) {
            console.log(error);
        }
    };

    // Periodically check whether the job is currently running
    useEffect(() => {
        const intervalId = setInterval(() => {
            isJobRunning();
        }, 2000);

        const isJobRunning = async() => { 
            try {
                // Get the runs for this job
                const running_job_data = await axios.post('https://pipelines-api.azurewebsites.net/api/DatabricksGetJobRunList', {jobId: jobId});

                // If the latest run is in a certain state, that means it's running
                if (running_job_data.data['runs']['0']['state']['life_cycle_state'] === "PENDING" || running_job_data.data['runs']['0']['state']['life_cycle_state'] === "RUNNING") {
                    setJobIsRunning(true);
                } else {
                    setJobIsRunning(false);
                }
            } catch(error) {
                console.log(error);
            }
        }
        // When component unmounts, clear the interval
        return () => clearInterval(intervalId);
    }, [jobId]);

    // Change the functionality of the button based on whether the job is running
    if (jobIsRunning) {
        // If the job is already running, disable the function of the clickable icon
        return (
            <div className= "button-non-clickable-icon" disabled = {true} >
            <img 
                src = {databricks_logo_grey}
                width = "35%"
                alt = "databricks_icon"
                className="busy-icon"
            />
            <p> Job bezig... </p>
        </div>
        )

    } else {
        // Returns a component with a clickable icon that STARTS the job
        return (
            <div className= "button-clickable-icon" onClick={onClickHandlerRun}>
                {/* TODO - check how to properly format the icon in the button. It's not really resizing nicely based on window width currently.*/}
                <img 
                    src = {databricks_logo}
                    width = "35%"
                    alt = "databricks_icon"
                />
                <p> Run Job </p>
            </div>
        );
    }
}

/**
 * A container component that contains the clickable icon component and the status box component
 * 
 * @param {string} jobId the ID of the job
 * @returns Two components that form the main content
 */
function ButtonMainContent({jobId}) {

    return (
        <div className="button-main-content"> 
            <ButtonClickableIcon
                jobId = {jobId}
            />
            <ButtonStatusBox
                jobId= {jobId}
            />
        </div>
    )
}

/**
 * A button consists of 4 different components: 
 *  - A header
 *  - A clickable component
 *  - A status box
 *  - A description footer
 * 
 * This function is meant to group those components into one single component, hence allowing this component 
 * to be reused in the app
 * 
 * @param {string} jobId The ID of the job
 * @returns A component consisting of the 4 components listed above
 */
function Button( {jobId} ) {
    return (
        <div className="button-container"> 

            <ButtonHeader
                jobId = {jobId}
            />

            <ButtonMainContent
                jobId = {jobId}
            />
            
            <ButtonSummaryText
                jobId = {jobId}
            />

        </div>
    )
}

export default Button;
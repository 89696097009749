import React from 'react';
import ReactDOM from 'react-dom';
import './fonts.css';

import App from './App';
import MUIApp from './MUIApp.js';

import { createTheme } from '@mui/material/styles';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalConfig = {
    auth: {
      clientId: 'e16f4c8e-3e07-4e76-89b4-94928f6ab441',
      authority: 'https://login.microsoftonline.com/9a35da94-4582-438b-9783-2d58bb299b6e',
      redirectUri: window.location.origin + "/auth-callback",
    },
    cache: {
      cacheLocation: 'sessionStorage', // Recommended to use sessionStorage for single-page applications
      storeAuthStateInCookie: false, // Set this to true if you are having issues with redirects
    },
  };
  
  const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render( 
    <React.StrictMode>

        <MsalProvider instance = {msalInstance}> 

              <MUIApp/>
            
        </MsalProvider>

    </React.StrictMode>
)
